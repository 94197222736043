<template>
  <div>
    <sub-header
      buttonText="Create Currency"
      :handleClick="createCurrency"
      :showBtn="true"
    ></sub-header>
    <div class="flex relative flex-1 flex-col flex-grow max-h-full max-w-full">
      <vue-good-table
        :isLoading="loading"
        :columns="computedColumns" 
        :rows="rows"
        :pagination-options="{ enabled: true, mode: 'records', dropdownAllowAll: false }"
        @on-column-filter="onColumnFilter" 
        v-bind="config" 
        :totalRows="pageData.total_count"
        @on-per-page-change="onPageChange($event, true)" 
        @on-page-change="onPageChange($event)"
        @on-selected-rows-change="selectionChanged">

      >
      <template #column-filter="{ column, updateFilters }">
                <div v-if="column.filterOptions && column.filterOptions.enabled && column.config && column.config.filter_type === 'date'">
                    <DatePicker range v-model="date_range" @input="(value) => updateColumnFilters(column, value, updateFilters)" 
                        :key="date_range_key" class="w-full" format="DD MMM, YYYY" value-type="timestamp"  :placeholder="due_date" />
                    </div>
                <dropdown
                    :ref="setFilterRef"
                    reposition
                    searchable
                    class="filter-dropdown"
                    placeholder="Select"
                    v-else-if="column.filterOptions && column.filterOptions.enabled && column.config && !['indicators', 'allocation'].includes(column.config.type)"
                    :options="column.filterOptions.filterDropdownItems"
                    @input="(value) => updateColumnFilters(column, value, updateFilters)"
                    :value="getFilterValue(column)"
                    @search="searchFilter(column, $event)"
                    :limit="1"
                    maxWidth="100%"
                    width="100px"
                    minWidth="100%"
                    :config="{ label: 'name', trackBy: 'id' }"
                    :multiple="false"
                    :taggable="false"
                >
                    <template #caret>
                        <sort-icon class="fill-current" />
                    </template>
                </dropdown>
                <div class="" v-else>
                    <!-- // adding a blank div to remove the default template   -->
                </div>
              
            </template>
        <template slot="table-row" slot-scope="props">
          <div
            v-if="props.column.field === 'currency_code'"
            :class="[props.row.system_default && 'default hover-text']"
            class="flex gap-3 pl-3"
          >
            <router-link
              :to="{
                name: 'editCurrency',
                params: { id: props.row.id },
              }"
              class="text-primary whitespace-nowrap cursor-pointer hover:text-dvbrandhoveron"
              >{{
                props.row.base_currency
                  ? props.row.currency_code + "(Base Currency)"
                  : props.row.currency_code
              }}</router-link
            >
          </div>
          <div v-if="props.column.field == 'price_currency'" class="flex justify-center">
            <div v-if="props.row.master_currency && !props.row.base_currency">
              <font-awesome-icon class="items-center" icon="check" />
            </div>
          </div>
          <div v-if="props.column.field == 'currency_symbol'" class="flex justify-center">
            {{ props.row.currency_symbol }}
          </div>
          <div v-if="props.column.field == 'currency_name'" class="flex justify-center">
            {{ props.row.currency_name }}
          </div>
          <div v-if="props.column.field == 'decimal_place'" class="flex justify-center">
            {{ props.row.decimal_place }}
          </div>
        </template>
        <template #loadingContent>
          <div class="w-full py-10 justify-center flex">
            <Loader />
          </div>
        </template>
      </vue-good-table>
      <button 
        v-if="isFilters"
          class=" cursor-pointer py-1 px-3 bg-gray-100 hover:bg-gray-200 rounded-md text-xs text-black flex justify-center items-center font-semibold absolute  left-2 z-50" :class="isSelectionChanged ? 'top-14' : 'top-2' " @click="clearFilters">
          Clear filters
      </button>
    </div>
  </div>
</template>

<script>
import { getCurrencyList } from "./services";
import dropdown from "@shared/components/dropdown-base";
import SubHeader from "@/components/SubHeader";
import { onlyUnique } from "@shared/plugins/utils.js";
import { uuid } from "vue-uuid";
import { DateTime } from "luxon";
import Loader from "@shared/loader";
import DatePicker from "vue2-datepicker";
import sortIcon from "@shared/assets/svg/dropdown-double.svg";
import { format , parse } from "date-fns";

export default {
  name: "currency-list",
  data: function () {
    return {
      columns: [
        {
          label: "Currency Code",
          field: "currency_code",
          config: {
            filter: true,
            alignCenter: true,
            type: 'action_button',
          },
        },
        {
          label: "List Price Currency",
          field: "price_currency",
        },
        {
          label: "Symbol",
          field: "currency_symbol",
        },
        {
          label: "Currency Name",
          field: "currency_name",
        },
        {
          label: "Decimal Place",
          field: "decimal_place",
        },
      ],
      rows: null,
      loading: false,
      filters: {},
      filterRefs: [],
      searchLoading: {},
      serverParams: {},
      filterOptionsAsync: {}, 
      filterOptions: {},
      date_range: [],
      date_range_key: 1,
      isSelectionChanged: false,
      pageData: 
      {
        total_pages: 0,
      },
      currentPageData: {
        page: 1,
        limit: 10,
      },
    };
  },
  components: {
    SubHeader,
    Loader,
    dropdown,
    sortIcon,
    DatePicker,
  },
  async mounted() {
    this.loading = true;
    await this.setCurrencyList();
    this.filterOptions = this.columnFilterOptions
    this.loading = false;
  },
 computed: { 
  isFilters() {
            return this.serverParams && Object.entries(this.serverParams).length;
  },
  columnFilterOptions() {
    return this.getFilterOptions(this.columnOption, this.rows);
  },
  columnOption() {
    return this.columns.map((el) => ({
      ...el,
      config: {
              ...el.config,
              isLoading: !!this.searchLoading[el.field] || false,
              },
    }));
  },
  computedColumns() {
    // use option list from server if it is:
    const options = this.filterOptions;
    // had to check for empty object
    if (options && Object.entries(options).length) {
      return this.columns.map((col) => {
        const filtered = col.config?.filter ?? true;
        if (col.prefetchedOptions && col.prefetchedOptions.length) {
          return {
            ...col,
            filterOptions: {
              enabled: filtered,
              filterDropdownItems: col.prefetchedOptions,
            },
          };
        } else {
          return {
            ...col,
            filterOptions: {
              enabled: filtered,
              filterDropdownItems: options[col.field],
            },
          };
        }
      });
    } else {
      // TODO remove/rework default filters
      return this.columns.map((col) => {
        const filtered = col.config?.filter ?? true;
        return {
          ...col,
          filterOptions: filtered
            ? {
              enabled: true,
              placeholder: "All",
              filterDropdownItems: this.rows
                .filter((row) => row[col.field])
                .map((row) => ({ id: row.field_type_id ?? uuid.v4(), name: row[col.field] }))
                .filter(onlyUnique),
            }
            : undefined,
        };
      });
    }
  }
},
  methods: {
    async clearFilters() {
                this.serverParams = null;
                await this.setCurrencyList();
    },
    async onColumnFilter({ columnFilters }) {
                this.currentPageData.page = 1;
                this.serverParams = columnFilters ? { ...columnFilters } : null;
                await this.setCurrencyList();
    },
    selectionChanged(e) {
            // console.log('e :>> ', e);
            if (e) {
                this.isSelectionChanged = e.selectedRows && e.selectedRows.length !== 0;
                this.$emit('on-selected-rows-change', e.selectedRows)
            }
        },
    updateColumnFilters(column, value /* , callback */) {
      let filterValObj = {
        ...this.serverParams,
        [column.query_key || column.field]: value ? {
          ...value,
          field_type: column.field_type,
        } : null,
      };
      if (!filterValObj[column.query_key || column.field]) {
        delete filterValObj[column.query_key || column.field];
      }
      if (column.query_key && ["from_due_date", "from_created_at"].includes(column.query_key)) {
        let date = DateTime.fromISO(value.name).toISODate()
        if (date == null) {
          const parsedDate = parse(value.name, 'd MMM yyyy', new Date());
          date = format(parsedDate, 'yyyy-MM-dd');
          if (filterValObj.from_created_at) {
            filterValObj.from_created_at.name = date.toString().substring(10, 0)
          } else {
            filterValObj.from_due_date.name = date.toString().substring(10, 0)
          }
        }
        Object.assign(filterValObj, {
          [`to_${column.field}`]: {
            id: date.ts,
            name: date.toString().substring(10, 0),
            field_type: column.field_type
          },
        });
      }
      if (column.config && /* column.config.query_keys && */ column.config.filter_type === 'date') { // uncommment when you want to add customkeys for backend filters
        let [start, end] = value.map(el => DateTime.fromJSDate(new Date(el)))
        Object.assign(filterValObj, {
          [column.config.query_keys['start']]: {
            id: start.ts,
            name: value[0] ? start.toString() : null,
            field_type: column.field_type
          },
        })
        Object.assign(filterValObj, {
          [column.config.query_keys['end']]: {
            id: end.ts,
            name: value[1] ? end.toString() : null,
            field_type: column.field_type
          },
        })
      }
      this.onColumnFilter( {
        columnFilters: filterValObj,
      });
    },
    getFilterOptions(columns, row) {
      return columns.reduce((result, curr) => {
        result[curr.field] = row
          .filter((row) => row[curr.field])
          .map((row) => row[curr.field])
          .filter(onlyUnique)
          .map((el) => ({ id: uuid.v4(), name: el }));
        return result;
      }, {});
    },
    async setCurrencyList() {
      try {
        const filterParams = this.makeFilterParams();
        const { data } = await getCurrencyList(`page=${this.currentPageData.page}&limit=${this.currentPageData.limit}`, filterParams);
        const isMasterCurrency = [];
        const isNotMasterCurrency = [];
        let masterCurrencies = data.data
        this.pageData = data.pagination_info;
        if (!filterParams) {
          data.data.forEach(item => {
            if (item.master_currency === true) {
              isMasterCurrency.push(item);
            } else {
              isNotMasterCurrency.push(item);
            }
          });
          masterCurrencies = [isMasterCurrency[0], ...isMasterCurrency.slice(1), ...isNotMasterCurrency];
        }
        const sortedDataWithFirstGBP = masterCurrencies.sort((a, b) =>
          a.currency_code === "GBP" ? -1 : b.currency_code === "GBP" ? 1 : 0
        );
        this.rows = sortedDataWithFirstGBP;
      } catch (error) {
        console.error(error);
      }
    },
    makeFilterParams() {
                let filterParams = {};
                if (this.serverParams) {
                    Object.keys(this.serverParams).map((key) => {
                        filterParams[key] = this.serverParams[key]
                        ? this.serverParams[key]["name"]
                        : delete filterParams[key];
                        if (this.serverParams[key]["name"] === undefined) {
                            delete filterParams[key];
                        }
                        if (key.includes("date_from") || key.includes("date_to")) {
                            filterParams[key] = this.formatDate(filterParams[key]);
                        }
                    });
                }
                return filterParams;
            },
    async onPageChange(data, resetPage = false) {
                this.currentPageData = {
                    page: data.currentPage,
                    limit: data.currentPerPage,
                };
                if (resetPage) this.currentPageData.page = 1;
                await this.setCurrencyList();
    },
    searchFilter(column, value) {
            this.$emit("search-filter", { column, value });
    },
    setFilterRef(el) {
            if (el) {
                this.filterRefs.push(el);
            }
    },
    getFilterValue(col) {
            // isFilters ? filters[column.field] : null
            if (this.isFilters) {
                return this.serverParams[col.field] ?? this.serverParams[col.query_key];
            } else return null;
        },
    async createCurrency() {
      this.$router.push({ name: "createCurrency" });
    },
    // onColumnFilter(e) {
    //         console.log("onColumnFilter", e);
    // },
  },
};
</script>

<style scoped lang="scss">
@import "@shared/assets/mixins/mixins.scss";
@include tableComponent;
</style>
