import axios from "@/axios"

export async function getCurrencyList(params, filterParams) {
    const url = `/currency?${params}`
    return await axios.get(url, {
        params: {
            ...filterParams
        }
    })
}

export async function getCurrencyCodes() {
    const url = '/currency/static'
    return axios.get(url)
}

export async function createCurrency(payload) {
    const url = "/currency"
    return axios.post(url, payload)
}

export async function getCurrencyDetails(currencyId) {
    const url = `/currency/${currencyId}`
    return axios.get(url)
}

export async function editCurrency(payload) {
    const url = "/currency"
    return axios.put(url, payload)
}